import React, { useState } from 'react';

const PasswordValidation = () => {


  return (
    <div className="form-row passwordValid">
      <div className="form-holder">
        <h4>Password Should</h4>
        <span style={{ color: 'red', top: '6px', position: 'relative' }} className="password_warning"></span>
        <p id="letter" className="valid">
          At least <b>1 lowercase</b> letter
        </p>
        <p id="capital" className="valid">
          At least <b>1 capital (uppercase)</b> letter
        </p>
        <p id="number" className="valid">
          At least <b>1 non-letter</b>
        </p>
        <p id="length" className="valid">
          Minimum <b>8 characters</b>
        </p>
        <p id="maxlength" className="valid">
          Maximum <b>15 characters</b>
        </p>
        <p id="specialChar" className="valid">
          Minimum <b>1 Special characters</b>
        </p>
      </div>
    </div>
  );
};

export default PasswordValidation;
